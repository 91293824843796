import header from './header.html';
import Download from './download/download';
import template from './order.html';
import './order.less';

export default angular.module('eventix.order', [Download])
    .config(function($stateProvider, GoogleTagManagerProvider) {
        $stateProvider.state('eventix.order', {
            url: '/status/:order_guid/:status',
            params: {
                status: { // To support links without the status in the url
                    value: null,
                    squash: true
                }
            },
            views: {
                main: {
                    controller: 'OrderStatusController',
                    controllerAs: 'vm',
                    templateUrl: template
                },
                header: {
                    controller: 'OrderStatusHeaderController',
                    controllerAs: 'vm',
                    templateUrl: header
                }
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$, $location, $state) {
                    if(typeof USE_ORDER_CACHE !== 'undefined' && USE_ORDER_CACHE == 1){
                        // peak shop, dont fetch order here
                        return null;
                    }
                    return Order.getPublic($transition$.params().order_guid).then(order => {
                        // Load Tag as early as possisble
                        let gtm = _.get($location.search(), 'gtm', 1);

                        if (gtm !== 0 && gtm !== '0' && order.shop.google_tag) {
                            GoogleTagManagerProvider.addContainer(order.shop.google_tag);
                        }

                        return order;
                    });
                }
            }
        });
    })
    .controller('OrderStatusController', function($state, $location, order, $analytics, Locales, $translate, UIMessages, $q, $scope, $uibModal, ShopAnalytics) {
        const vm = this;

        vm.setLocale = setLocale;

        vm.locales = Locales;

        vm.supportValues = {
            email: $scope.branding.email || 'tickets@eventix.nl'
        };

        if(!_.isNull(order)) {
            vm.order = order;
            vm.shop = order.shop;
            vm.payment = _.first(order.payments);

            vm.shopUrl = FANCY_SHOP_ROOT + '/' + order.shop_id;

            vm.ticketDownloadExpired = moment().diff(moment(vm.order.created_at, 'YYYY-MM-DDTHH:mm:ssZ'), 'minutes') > 15;

            vm.paid = _.get(vm.payment, 'status') === 'paid';
            vm.totalPaid = _.reduce(order.payments, (total, p) => p.status === 'paid' ? total + p.finn_price : total, 0);

            vm.currency = _.get(vm.payment, 'currency', DEFAULT_CURRENCY);
            vm.orderStatus = _.get(vm.order, 'status', 'pending');

            if (vm.payment && vm.payment.statii) {
                vm.error = _.get(_.first(_.sortBy(vm.payment.statii, function (statii) {
                    return new Date(statii.created_at);
                }).reverse()), 'reason');
            }

            ShopAnalytics.conversion(order);
            vm.orderGuid = _.get(vm.order, 'guid');
            vm.orderEmail = _.get(vm.order, 'email');
            vm.showPayment = true;
        }
        else{
            vm.paid  = _.get($location.search(), 'status', 'pending') === 'paid';
            vm.shopUrl = FANCY_SHOP_ROOT + '/' + _.get($location.search(), 'shop_id');
            vm.currency = _.get($location.search(), 'currency', DEFAULT_CURRENCY);
            vm.totalPaid = _.get($location.search(), 'amount', 0);
            vm.ticketDownloadExpired = true; // reduce load from download page, sent to email
            vm.orderStatus = _.get($location.search(), 'status', 'pending');
            vm.orderGuid = _.get($location.search(), 'order_id');
            vm.orderEmail = _.get($location.search(), 'email');
            vm.showPayment = false;
        }

        function setLocale(code) {
            $translate.use(code);
        }
    })
    .controller('OrderStatusHeaderController', function($state, $location, order, $analytics, Locales, $translate) {
        const vm = this;

        vm.setLocale = setLocale;
        vm.statusIcon = statusIcon;
        vm.statusText = statusText;

        vm.state = $state;
        vm.locales = Locales;

        if(!_.isNull(order)) {
            vm.order = order;
            vm.shopUrl = FANCY_SHOP_ROOT + '/' + order.shop_id;
            vm.orderStatus = _.get(vm.order, 'status', 'pending');
        }
        else{
            vm.order = order;
            vm.orderStatus = _.get($location.search(), 'status', 'pending');
            vm.shopUrl = FANCY_SHOP_ROOT + '/' + _.get($location.search(), 'shop_id');
        }

        function statusIcon() {
            switch (vm.orderStatus) {
            case 'pending':
                return 'fa fa-clock-o';
            case 'cancelled':
                return 'fa fa-times';
            case 'paid':
            default:
                return $state.includes('eventix.order.download') ? 'fa fa-download' : 'fa fa-check';
            }
        }

        function statusText() {
            if (vm.orderStatus === 'paid') {
                return $state.includes('eventix.download') ? 'common.shop.order.download' : 'common.shop.order.paid';
            }

            return 'common.shop.order.' + vm.orderStatus;
        }

        function setLocale(code) {
            $translate.use(code);
        }
    })
    .filter('isAutomaticProduct', function() {
        return function(input) {
            if (!_.isObject(input)) {
                return false;
            }

            return /^(Access for|Product for)/.test(_.get(input, 'name', ''));
        };
    })
    .name;
